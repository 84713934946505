import React from 'react';
import { GetServerSideProps } from 'next/types';
import { DefaultNoSpacing } from '@components/templates/layouts/DefaultNoSpacing';
import { GetBySlugFullDocument } from '@src/lib/graphql/generated';
import { fetchExtraPageData } from '@lib/graphql/helpers/fetchExtraPageData';
import { clearCache, ssrQuery } from '@lib/graphql/urql';
import { handleRedirect } from '@lib/redirects/redirect';
import { DefaultPage } from './[categoryOrPage]';

function Home({ data }) {
  return <div>{data && <DefaultPage data={data} />}</div>;
}

export const getServerSideProps: GetServerSideProps = async ({ res }) => {
  res.setHeader('Cache-Control', 'public, s-maxage=86400, stale-while-revalidate=2628000');

  clearCache();

  const { data: pageData } = await ssrQuery({
    query: GetBySlugFullDocument,
    variables: {
      portal: 'tvmedia',
      slug: '/home',
      coverImageWidth: 3840,
      coverImageHeight: 2160,
      coverImageFit: 'crop_focal',
    },
  });
  const data = pageData?.findBySlug;

  if (!data) return { notFound: true };

  // Handle redirect result
  if (data?.__typename === 'Redirect' && data?.redirect_type) {
    return handleRedirect(data?.redirect_type, data?.redirect_target);
  }

  // Handle listicle loadings
  if (data?.__typename === 'Page') {
    data.pageContent = await fetchExtraPageData(data?.pageContent);
  }

  return {
    props: {
      data,
      // urqlState: ssrCache.extractData(),
    },
  };
};

Home.PageLayout = DefaultNoSpacing;

export default Home;
